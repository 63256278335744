import {
  CaptionGroup,
  CaptionLayoutStyle,
  CaptionPreset,
  CaptionTextStyle,
  CaptionWord,
  Captions,
  Cut,
  WordTimecode,
} from "../types/player";
import { getTotalDurationOfCuts } from "./cuts";

const PUNCTUATIONS = [".", "!", "?", ",", ";"];

export type CaptionLayoutPresetKey = keyof typeof CAPTIONS_LAYOUT_PRESETS;
export const CAPTIONS_LAYOUT_PRESETS: { [name: string]: CaptionLayoutStyle } = {
  default: {
    minDuration: 2,
    maxWordsPerGroup: 5,
    maxLines: 2,
    position: {
      x: 0.5,
      y: 0.7,
    },
    animation: "spring",
    highlight: "text-fill-color",
    emojiStyle: "microsoft-fluent",
  },
  singleWord: {
    minDuration: 0.5,
    maxWordsPerGroup: 3,
    maxLines: 1,
    position: {
      x: 0.5,
      y: 0.7,
    },
    animation: "spring",
    highlight: "text-fill-color",
    emojiStyle: "none",
  },
  box: {
    minDuration: 2,
    maxWordsPerGroup: 5,
    maxLines: 2,
    position: {
      x: 0.5,
      y: 0.7,
    },
    animation: "none",
    highlight: "box",
    emojiStyle: "none",
  },
};

export const CAPTIONS_TEXT_PRESETS: { [name: string]: CaptionTextStyle } = {
  default: {
    fill: "#FFFFFF",
    stroke: "#000000",
    highlight: "#FFFF00",
    case: "uppercase",
    fontSrc:
      "http://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLBT5V1tvFP-KUEg.ttf",
    fontSize: 64,
    fontFamily: "Poppins",
  },
  defaultWhite: {
    fill: "#cddcf4",
    stroke: "#103e89",
    highlight: "#75FA55",
    case: "lowercase",
    fontSrc:
      "http://fonts.gstatic.com/s/titanone/v13/mFTzWbsGxbbS_J5cQcjykzIn2Etikg.ttf",
    fontSize: 128,
    fontFamily: "Titan One",
  },
  2: {
    fill: "#cddcf4",
    stroke: "black",
    highlight: "#E94358",
    case: "lowercase",
    fontSrc:
      "http://fonts.gstatic.com/s/librebaskerville/v14/kmKiZrc3Hgbbcjq75U4uslyuy4kn0qviTjYwI8Gcw6Oi.ttf",
    fontSize: 64,
    fontFamily: "Libre Baskerville",
  },
  3: {
    highlight: "#5cd3ff",

    fill: "white",
    stroke: "black",

    case: "uppercase",
    fontSrc:
      "http://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLBT5V1tvFP-KUEg.ttf",
    fontSize: 64,
    fontFamily: "Poppins",
  },
};

export const CAPTIONS_PRESETS: { [name: string]: CaptionPreset } = {
  default: {
    // DO NOT CHANGE THIS ID
    id: "492856e0-fddd-4fed-9c60-2bd256115baf",
    name: "Klap fill text",
    captionsLayoutStyle: CAPTIONS_LAYOUT_PRESETS.default,
    captionsTextStyle: CAPTIONS_TEXT_PRESETS.default,
    cropsActive: true,
    captionsActive: true,
    layers: [],
  },
  singleWord: {
    // DO NOT CHANGE THIS ID
    id: "b0728c24-ad53-4bc8-9f69-0d09f7aff0f9",
    name: "Klap single word",
    captionsLayoutStyle: CAPTIONS_LAYOUT_PRESETS.singleWord,
    captionsTextStyle: CAPTIONS_TEXT_PRESETS.defaultWhite,
    cropsActive: true,
    captionsActive: true,
    layers: [],
  },
  singleWord2: {
    // DO NOT CHANGE THIS ID
    id: "2698fc2a-d9a5-4294-b568-892d41ca2143",
    name: "Klap serif",

    captionsLayoutStyle: CAPTIONS_LAYOUT_PRESETS.default,
    captionsTextStyle: CAPTIONS_TEXT_PRESETS["2"],
    cropsActive: true,
    captionsActive: true,
    layers: [],
  },
  highlightBox: {
    // DO NOT CHANGE THIS ID
    id: "2e5e4277-d535-47c2-a1c8-1b05696e1f6a",
    name: "Klap box",
    captionsLayoutStyle: CAPTIONS_LAYOUT_PRESETS.box,
    captionsTextStyle: CAPTIONS_TEXT_PRESETS["3"],
    cropsActive: true,
    captionsActive: true,
    layers: [],
  },
};

export type EnrichedCaptionWord = CaptionWord &
  WordTimecode & { startFrame?: number; endFrame?: number };
export type EnrichedCaptions = EnrichedCaptionWord[][];

export const captionsToEnrichedCaptions = (
  captions: Captions,
  wordTimecodes: WordTimecode[]
): EnrichedCaptions =>
  captions.map((group) =>
    group.map((word) => ({ ...wordTimecodes[word.id], ...word }))
  );

export const enrichedCaptionsToCaptions = (
  enrichedCaptionsToCaptions: EnrichedCaptions
): Captions => {
  return enrichedCaptionsToCaptions.map((group) =>
    group.map((word) => ({ id: word.id }))
  );
};
// export const timecodesToCaptions = (
//   wordsTimecodes: WordTimecode[],
//   groupSize: number = 6,
//   maxLines: number = 2 // Added new argument for maximum lines
// ): Captions => {
//   if (wordsTimecodes.length === 0) {
//     return [];
//   }

//   let groupedWords: Captions = [];
//   let group: CaptionGroup = [];
//   let prevEnd = wordsTimecodes[0]["end"] as number;
//   let prevSegmentId = wordsTimecodes[0]["segment_id"] as number;
//   let punctuationMarks = [".", "!", "?", ",", ";"];

//   for (let i = 0; i < wordsTimecodes.length; i++) {
//     const wordTimecode = wordsTimecodes[i];
//     if (
//       (wordTimecode["start"] as number) - prevEnd > 0.1 ||
//       group.length >= groupSize ||
//       (wordTimecode["segment_id"] as number) !== prevSegmentId ||
//       (i > 0 &&
//         punctuationMarks.includes(
//           (wordsTimecodes[i - 1]["text"] as string).slice(-1)
//         ))
//     ) {
//       groupedWords.push(group);
//       group = [];
//     }

//     group.push({ id: i });

//     prevEnd = wordTimecode["end"] as number;
//     prevSegmentId = wordTimecode["segment_id"] as number;
//   }

//   if (group.length > 0) {
//     groupedWords.push(group);
//   }

//   // Here we calculate the average number of characters per line
//   const totalChars = wordsTimecodes.reduce(
//     (total, wordTimecode) => total + (wordTimecode["text"] as string).length,
//     0
//   );
//   const avgCharsPerLine = Math.ceil(totalChars / maxLines);

//   for (let i = 0; i < groupedWords.length; i++) {
//     let group = groupedWords[i];
//     const lines: CaptionGroup[] = Array(maxLines).fill([]);
//     let lineCharCounts: number[] = Array(maxLines).fill(0);
//     let lineIndex = 0;

//     for (let j = 0; j < group.length; j++) {
//       const word = group[j];
//       if (
//         lineCharCounts[lineIndex] +
//           (wordsTimecodes[word["id"] as number]["text"] as string).length >
//           avgCharsPerLine &&
//         lineIndex < maxLines - 1
//       ) {
//         lineIndex++;
//       }
//       lines[lineIndex].push(word);
//       if (word["id"] !== -1) {
//         lineCharCounts[lineIndex] += (
//           wordsTimecodes[word["id"] as number]["text"] as string
//         ).length;
//       }
//     }

//     // Combine lines
//     let newGroup: CaptionGroup = [];
//     for (let line of lines) {
//       if (line.length > 0) {
//         newGroup = newGroup.concat(line);
//         newGroup.push({ id: -1 }); // Add a separator between lines
//       }
//     }
//     groupedWords[i] = newGroup;
//   }

//   for (let i = 0; i < groupedWords.length; i++) {
//     let group = groupedWords[i];
//     if (group[0]["id"] === -1) {
//       group = group.slice(1);
//     }
//     if (group[group.length - 1]["id"] === -1) {
//       group = group.slice(0, -1);
//     }
//     groupedWords[i] = group;
//   }

//   return groupedWords;
// };

export const createCaptionsGroup = (
  captions: Captions,
  wordTimecodes: WordTimecode[],
  minDuration: number = 2,
  maxWordsPerGroup: number = 5
): EnrichedCaptions => {
  const enrichedCaptionGroups = captionsToEnrichedCaptions(
    captions,
    wordTimecodes
  );

  // Make sure captions do not contain breaklines
  const flattenCaptions = enrichedCaptionGroups
    .flat()
    .filter((c) => c.id !== -1);

  const newGroups: EnrichedCaptions = [];
  let tempGroup: any = [];
  let totalDuration = 0;

  for (let i = 0; i < flattenCaptions.length; i++) {
    const caption = flattenCaptions[i];

    const endWithPunctuation = PUNCTUATIONS.includes(
      caption.text.trim().charAt(caption.text.trim().length - 1)
    );

    const duration = caption.end - caption.start;

    if (
      totalDuration + duration <= minDuration &&
      tempGroup.length < maxWordsPerGroup &&
      !endWithPunctuation
    ) {
      tempGroup.push(caption);
      totalDuration += duration;
    } else {
      if (tempGroup.length > 0) {
        newGroups.push(tempGroup);
      }
      tempGroup = [caption]; // Start new group with current caption
      totalDuration = duration; // Reset totalDuration
    }

    if (endWithPunctuation) {
      newGroups.push(tempGroup);
      tempGroup = []; // Reset tempGroup
      totalDuration = 0; // Reset totalDuration
    }

    // Push the last tempGroup into newGroups if it's the last caption
    if (i === flattenCaptions.length - 1 && tempGroup.length > 0) {
      newGroups.push(tempGroup);
    }
  }
  // // console.log(newGroups);

  return newGroups;
};
export const createCaptionsGroupV2 = (
  wordTimecodes: WordTimecode[],
  minDuration: number = 2,
  maxWordsPerGroup: number = 5
): EnrichedCaptions => {
  const newGroups: EnrichedCaptions = [];
  let tempGroup: any = [];
  let totalDuration = 0;

  for (let i = 0; i < wordTimecodes.length; i++) {
    const word = wordTimecodes[i];

    const endWithPunctuation = PUNCTUATIONS.includes(
      word.text.trim().charAt(word.text.trim().length - 1)
    );

    const duration = word.end - word.start;
    const caption = {
      id: i,
    };

    if (
      totalDuration + duration <= minDuration &&
      tempGroup.length < maxWordsPerGroup &&
      !endWithPunctuation
    ) {
      tempGroup.push(caption);
      totalDuration += duration;
    } else {
      if (tempGroup.length > 0) {
        newGroups.push(tempGroup);
      }
      tempGroup = [caption]; // Start new group with current caption
      totalDuration = duration; // Reset totalDuration
    }

    if (endWithPunctuation) {
      newGroups.push(tempGroup);
      tempGroup = []; // Reset tempGroup
      totalDuration = 0; // Reset totalDuration
    }

    // Push the last tempGroup into newGroups if it's the last caption
    if (i === wordTimecodes.length - 1 && tempGroup.length > 0) {
      newGroups.push(tempGroup);
    }
  }
  return newGroups;
};

export const distributeWords = (
  enrichedCaptions: EnrichedCaptions,
  maxLines: number = 2
): Captions => {
  let groupedWords: Captions = [];
  for (let i = 0; i < enrichedCaptions.length; i++) {
    const totalChars = enrichedCaptions[i].reduce(
      (total, enrichedCaption) => total + enrichedCaption.text.length,
      0
    );
    const avgCharsPerLine = Math.ceil(totalChars / maxLines);
    let group = enrichedCaptions[i];
    let lines: CaptionGroup[] = new Array(maxLines).fill(null).map(() => []);
    let lineCharCounts: number[] = Array(maxLines).fill(0);
    let lineIndex = 0;

    for (let j = 0; j < group.length; j++) {
      const word = group[j];
      if (
        lineCharCounts[lineIndex] + word.text.length > avgCharsPerLine &&
        lineIndex < maxLines - 1
      ) {
        lineIndex++;
      }
      lines[lineIndex].push({ id: word.id });
      if (word["id"] !== -1) {
        lineCharCounts[lineIndex] += word.text.length;
      }
    }

    // Combine lines
    let newGroup: CaptionGroup = [];
    for (let lineIndex = 0; lineIndex < lines.length; lineIndex++) {
      let line = lines[lineIndex];
      if (line.length > 0) {
        newGroup = newGroup.concat(line);
        // Only add a separator if there are more lines remaining
        if (lineIndex < lines.length - 1) {
          newGroup.push({ id: -1 }); // Add a separator between lines
        }
      }
    }
    groupedWords[i] = newGroup;
  }

  // // console.log("DISTRIB ", groupedWords);

  return groupedWords;
};

export const updateCaptionsLayout = (
  wordTimecodes: WordTimecode[],
  options: CaptionLayoutStyle
) => {
  const newCaptions = createCaptionsGroupV2(
    wordTimecodes,
    options.minDuration,
    options.maxWordsPerGroup
  );

  const enrichedCaption = captionsToEnrichedCaptions(
    newCaptions,
    wordTimecodes
  );

  return distributeWords(enrichedCaption, options.maxLines);
};

export const createPseudoElementsStrokeStyleId = (
  id: string,
  color: string
) => {
  return `${id}-${color.replace("#", "")}`;
};

export const getPseudoElementsStrokeStyle = (id: string, color: string) => {
  return `#${createPseudoElementsStrokeStyleId(
    id,
    color
  )} .text-stroke::before, #${createPseudoElementsStrokeStyleId(
    id,
    color
  )} .text-stroke::after {
    -webkit-text-stroke-color: ${color};
  }`;
};

export const filterCaptionInCut = (captions: EnrichedCaptions, cut: Cut) => {
  return captions
    .filter((group) =>
      group.some((word) => isWithinCut(word.start, word.end, cut))
    )
    .map((group) =>
      group
        .filter(
          (word) => word.id == -1 || isWithinCut(word.start, word.end, cut)
        )
        // remove the first break line if it's the first word
        .filter((word, idx) => !(idx == 0 && word.id == -1))
    );
};

export function isWithinCut(start: number, end: number, cut: Cut) {
  // Only for the edge case where the word has no duration
  if (start == end) return start >= cut?.start && start <= cut?.end;
  return end > cut?.start && start < cut?.end;
}

export function isWithinTime(start: number, end: number, time: number) {
  return start <= time && end > time;
}

// export const getRelativeCaptions = (
//   captions: EnrichedCaptions,
//   cuts: Cut[]
// ): EnrichedCaptions => {
//   return cuts
//     .map((cut, i) => {
//       const captionsWithinCut = filterCaptionInCut(captions, cut);
//       const currentSeekDuration = getTotalDurationOfCuts(cuts.slice(0, i));

//       const relativeGroup = captionsWithinCut.map((group) => {
//         return group.map((word) => {
//           const seekInCut = word.start - cut.start;
//           const seekTime = seekInCut + currentSeekDuration;
//           if (word.id == -1) return word;
//           return {
//             ...word,
//             start: seekTime,
//             end: seekTime + (word.end - word.start),
//           };
//         });
//       });
//       return relativeGroup;
//     })
//     .flat();
// };

export const getRelativeCaptions = (
  captions: EnrichedCaptions,
  cuts: Cut[]
): EnrichedCaptions => {
  if (cuts.length == 0) return captions;

  const relativeCaptions: EnrichedCaptions = [];
  for (let i = 0; i < cuts.length; i++) {
    const cut = cuts[i];
    const captionsWithinCut = filterCaptionInCut(captions, cut);
    const currentSeekDuration = getTotalDurationOfCuts(cuts.slice(0, i));

    for (const group of captionsWithinCut) {
      const relativeGroup: EnrichedCaptionWord[] = [];
      for (let i = 0; i < group.length; i++) {
        const word = group[i];
        const seekInCut = word.start - cut.start;
        const seekTime = seekInCut + currentSeekDuration;

        // Don't add break line if it's the first word
        if (word.id == -1) {
          if (relativeGroup.length == 0 || i == group.length - 1) continue;
          relativeGroup.push(word);
          continue;
        }

        // Don't add word if it's already in the relative group (happens when the word is in multiple cuts)
        const wordAlreadyInRelativeGroup = relativeCaptions
          .flat()
          .some((w) => w.id == word.id);

        if (wordAlreadyInRelativeGroup) continue;

        relativeGroup.push({
          ...word,
          start: seekTime,
          end: seekTime + (word.end - word.start),
        });
      }
      if (relativeGroup.length > 0) relativeCaptions.push(relativeGroup);
    }
  }

  return relativeCaptions;
};

export const magnetCaptions = (
  captions: EnrichedCaptions,
  timeThreshold = 1
) => {
  return captions.map((group, i) => {
    const currentGroupEndWord = group[group.length - 1];
    const nextGroup = captions[i + 1];

    if (!nextGroup) return group;
    const nextGroupStartWord = nextGroup[0];

    const delayBetweenGroups =
      nextGroupStartWord.start - currentGroupEndWord.end;

    if (delayBetweenGroups > timeThreshold || delayBetweenGroups < 0)
      return group;

    currentGroupEndWord.end = nextGroupStartWord.start;

    return group;
  });
};

export const maxDurationCaptions = (
  captions: EnrichedCaptions,
  maxDuration: number = 0.5
) => {
  return captions.map((caption) =>
    caption.map((word) => {
      if (word.id == -1) return word;

      if (word.end - word.start > maxDuration) {
        return {
          ...word,
          start: word.start,
          end: word.start + maxDuration,
        };
      }

      return word;
    })
  );
};

export const groupWordTimecodesBySentences = (
  wordTimecodes: WordTimecode[]
) => {
  const reSegmentedWordTimecodes: WordTimecode[][] = [];
  let currentSentenceWordTimecodes = [];
  let currentSegmentId = 0;
  let currentSegmentWordCount = 0;

  for (let i = 0; i < wordTimecodes.length; i++) {
    const word = wordTimecodes[i];

    currentSentenceWordTimecodes.push(word);
    currentSegmentWordCount++;

    // if we encounter a segment with more than 70 words, we assume sentence segmentation is not possible
    // most probably the transcript is not in english
    // if (currentSegmentWordCount >= 700) return wordTimecodes;

    if ([".", "?", "!"].some((char) => word.text.trimEnd().endsWith(char))) {
      reSegmentedWordTimecodes.push(currentSentenceWordTimecodes);
      currentSegmentWordCount = 0;
      currentSegmentId++;

      currentSentenceWordTimecodes = [];
    }
  }

  return reSegmentedWordTimecodes;
};
