import { Captions, Cut, FrameBasedCut, WordTimecode } from "../types/player";
import { isWithinCut, isWithinTime } from "./caption";

export interface CutWithWords {
  cut: Cut;
  words: WordTimecode[];
}

export function getCutsWithWords(
  videoCuts: Cut[],
  wordsTimestamps: WordTimecode[]
): CutWithWords[] {
  return videoCuts.map((cut) => {
    const wordsInCut = wordsTimestamps?.filter((word) =>
      isWithinCut(word.start, word.end, cut)
    );

    return { cut, words: wordsInCut };
  });
}

export interface GroupedWords {
  cutIndex: number;
  words: WordTimecode[];
}

export const groupWordsByCuts = (
  wordTimecodes: WordTimecode[],
  cuts?: Cut[]
) => {
  const groupedWords = [];

  let currentCutIndex = 0;
  let currentCutWords: WordTimecode[] = [];

  for (const word of wordTimecodes) {
    if (
      cuts &&
      currentCutIndex < cuts.length &&
      isWithinCut(word.start, word.end, cuts[currentCutIndex])
    ) {
      currentCutWords.push(word);
    } else {
      if (currentCutWords.length > 0) {
        groupedWords.push({
          cutIndex: currentCutIndex,
          words: currentCutWords,
        });
        currentCutWords = [];
        currentCutIndex++;
      }
      groupedWords.push({ cutIndex: -1, words: [word] });
    }
  }

  if (currentCutWords.length > 0) {
    groupedWords.push({
      cutIndex: currentCutIndex,
      words: currentCutWords,
    });
  }

  return groupedWords;
};

export function getNewCutsTimecodes(videoCuts: Cut[]): Cut[] {
  let currentTime = 0;
  return videoCuts.map((cut) => {
    const duration = cut.end - cut.start;
    const newCut = { start: currentTime, end: currentTime + duration };
    currentTime += duration;
    return newCut;
  });
}

export function getCutsWordsTimecodes(
  videoCuts: Cut[],
  wordsTimestamps: WordTimecode[]
): WordTimecode[] {
  if (videoCuts.length == 0) return wordsTimestamps;
  const newCutsTimecodes = getNewCutsTimecodes(videoCuts);
  const cutsWithWords = getCutsWithWords(videoCuts, wordsTimestamps);

  return cutsWithWords
    .map(({ cut, words }, i) => {
      const newCut = newCutsTimecodes[i];
      const timeOffset = newCut.start - cut.start;

      const newWords = words?.map((word) => ({
        ...word,
        start: word.start + timeOffset,
        end: word.end + timeOffset,
      }));

      return newWords;
    })
    .flat();
}

export function getTotalDurationOfCuts(videoCuts: Cut[]): number {
  return videoCuts?.reduce((acc, cut) => acc + (cut.end - cut.start), 0);
}

export function getMagnetedCuts(videoCuts: Cut[]): Cut[] {
  let time = 0;
  return videoCuts.map((cut) => {
    const newCut: Cut = { start: time, end: time + (cut.end - cut.start) };
    time += cut.end - cut.start;

    return newCut;
  });
}

export function getCurrentTimeOfCuts(
  time: number,
  currentCutIndex: number,
  videoCuts: Cut[]
): number {
  const currentCut = videoCuts[currentCutIndex];
  return time - currentCut.start;
}

export function getCaptionsWithinCut(
  cuts: Cut[],
  wordTimecodes: WordTimecode[],
  captions: Captions
): Captions {
  const enrichedCaptionGroups = captions.map((group) =>
    group.map((word) => ({ ...wordTimecodes[word.id], ...word }))
  );
  let filteredCaptionGroups: Captions = [];

  cuts.forEach((cut) =>
    enrichedCaptionGroups.forEach((group) => {
      if (group[0].start >= cut.start && group[group.length - 1].end <= cut.end)
        filteredCaptionGroups.push(group);
    })
  );

  return filteredCaptionGroups;
}

export const converCutsToRelative = (cuts: Cut[]) => {
  const newCuts = [...cuts];
  return newCuts.map((word) => {
    // get all the cuts before the current word
    const previousCuts = cuts.filter((cut) => cut.end <= word.start) ?? [];

    // // console.log("previousCuts", previousCuts);
    // // console.log("offsetTimeDuration", offsetTimeDuration);
    const cut = cuts.find(
      (cut) => word.end >= cut.start && word.start <= cut.end
    );
    // // console.log("cut", cut);

    const offsetTime = cut ? cut.start : 0;
    const currentSeekDuration = getTotalDurationOfCuts(previousCuts);
    // // console.log("currentSeekDuration", currentSeekDuration);
    const seekInCut = word.start - offsetTime;
    // // console.log("seekInCut", seekInCut);
    const seekTime = seekInCut + currentSeekDuration;
    // // console.log("seekTime", seekTime);

    const relativeWord = {
      start: seekTime,
      end: seekTime + (word.end - word.start),
    };
    // // console.log("relativeWord", relativeWord);
    return relativeWord;
  });
};

export const normalizeCuts = (cuts: Cut[], fps: number) => {
  const frameInSec = 1 / fps;

  // Remove cuts that are 1 frame or less
  return normalizeOverlappingCuts(cuts).filter(
    (cut) => cut.end - cut.start > frameInSec
  );
};

export const normalizeOverlappingCuts = (cuts: Cut[]): Cut[] => {
  const normalizedCuts: Cut[] = [...cuts];
  normalizedCuts.sort((a, b) => a.start - b.start);

  for (let i = 0; i < normalizedCuts.length - 1; i++) {
    const currentCut = normalizedCuts[i];
    const nextCut = normalizedCuts[i + 1];

    // Check if there's an overlap
    if (currentCut.end > nextCut.start) {
      // Calculate midpoint of the overlap
      const midPoint = (currentCut.end + nextCut.start) / 2;

      // Adjust the cuts so they don't overlap, and split at the midpoint
      currentCut.end = midPoint;
      nextCut.start = midPoint;
    }
  }

  return normalizedCuts;
};

export function normalizeOverlappingCuts2(cuts: Cut[]): Cut[] {
  // Sort cuts by start time
  cuts.sort((a, b) => a.start - b.start);

  let result: Cut[] = [];

  // Iterate through each cut
  for (let i = 0; i < cuts.length; i++) {
    let current = cuts[i];
    let overlapFound = false;

    // Compare with every other cut to find overlap
    for (let j = 0; j < cuts.length; j++) {
      if (i !== j) {
        let other = cuts[j];
        if (current.start < other.end && other.start < current.end) {
          // Overlap detected, create new cuts
          overlapFound = true;
          if (current.start < other.start) {
            result.push({ start: current.start, end: other.start });
          }
          if (current.end > other.end) {
            result.push({ start: other.end, end: current.end });
          }
          // Create a cut for the overlapping part
          result.push({
            start: other.start,
            end: Math.min(current.end, other.end),
          });
        }
      }
    }
    // If no overlap was found, push the current cut as is
    if (!overlapFound) {
      result.push(current);
    }
  }

  // Remove duplicate cuts
  result = result.filter(
    (cut, index, self) =>
      index ===
      self.findIndex((t) => t.start === cut.start && t.end === cut.end)
  );

  // Sort again since new cuts might not be in order
  result.sort((a, b) => a.start - b.start);

  return result;
}

export function splitCut(originalCut: Cut, splitPoints: number[]): Cut[] {
  // Filter out split points that are outside the range of the original cut
  const validSplitPoints = splitPoints.filter(
    (point) => point > originalCut.start && point < originalCut.end
  );

  // Sort the split points in ascending order
  validSplitPoints.sort((a, b) => a - b);

  const cuts: Cut[] = [];
  let currentStart = originalCut.start;

  for (const point of validSplitPoints) {
    cuts.push({ start: currentStart, end: point });
    currentStart = point;
  }

  // Add the final cut from the last split point to the original end
  cuts.push({ start: currentStart, end: originalCut.end });

  return cuts;
}

/**
 * Converts an array of time-based cuts to frame-based cuts.
 * @param cuts Array of cuts with start and end times in seconds.
 * @param frameRate Frame rate of the video (frames per second).
 * @returns Array of cuts with start and end converted to frame numbers.
 */
export function convertToFrameBasedCuts(
  cuts: Cut[],
  frameRate: number
): FrameBasedCut[] {
  return cuts.map((cut) => ({
    startFrame: Math.round(cut.start * frameRate),
    // Using Math.round for the end time and subtracting 1 to make the end frame exclusive
    endFrame: Math.round(cut.end * frameRate) - 1,
  }));
}

export const getCurrentCutsFromTime = (cuts: Cut[], time: number): Cut[] => {
  return cuts.filter((cut) => isWithinTime(cut.start, cut.end, time));
};
